export const accountNumberSlice = (numb) => {
  let accountNumberArray = [numb.slice(0, 2), " "];
  for (let currentIndex = 2; currentIndex <= numb.length; currentIndex += 4) {
    if (currentIndex < numb.length - 4) {
      accountNumberArray.push(numb.slice(currentIndex, currentIndex + 4), " ");
    } else {
      accountNumberArray.push(numb.slice(currentIndex, currentIndex + 4));
    }
  }
  return accountNumberArray.map((slicedAccountNumber) => slicedAccountNumber);
};
