import React from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";

import clsx from "clsx";

const CTALink = ({ title, to, className, colorClassNames, theme }) => {
  return (
    <Link
      className={clsx(
        className,
        "px-6 py-4 font-bold",
        "rounded-full shadow-lg transition duration-300 ease-in-out hover:scale-105 focus:scale-105 focus:outline-none",
        colorClassNames ??
          "bg-white/80 text-gray-800 hover:bg-white hover:text-teal-600 focus:bg-white focus:text-teal-600",
        theme.focus
      )}
      to={to}
    >
      {title}
    </Link>
  );
};

CTALink.propTypes = {
  className: PropTypes.string,
  colorClassNames: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    focus: PropTypes.string.isRequired,
  }).isRequired,
};

export default CTALink;
