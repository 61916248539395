import * as React from "react";

import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import clsx from "clsx";

import CTALink from "../components/CTALink";
import Layout from "../components/Layout";
import LostAndFoundCard from "../components/LostAndFoundCard";
import SectionContainer from "../components/SectionContainer";
import { SEO } from "../components/SEO";
import Waves from "../components/Waves";
import { ThemeContext } from "../context/ThemeContext";
import useTheme from "../hook/useTheme";
import { accountNumberSlice } from "../shared/utils/accountSclice";
import { dateDisplay } from "../shared/utils/dateDisplay";
import { getHeroSectionPreviewImageSrc } from "../shared/utils/image";
import { formatLocation } from "../shared/utils/location";

import LostAndFound from "./zagubione-znalezione";

const IndexPage = ({ data }) => {
  const { isHeightContrastEnable } = React.useContext(ThemeContext);

  const theme = useTheme(0);

  const [isAccountNumberCopied, setIsAccountNumberCopied] =
    React.useState(false);
  const lostAndFound = data?.directus?.lost_and_found ?? [];
  const heroSection = data?.directus?.hero_section ?? {};
  const mainSection = data?.directus?.main_section ?? {};

  const heroPhoto = getImage(
    data?.directus?.hero_section?.hero_image?.imageFile
  );

  return (
    <Layout theme={theme}>
      <SEO
        urlPath="/"
        imagePath={getHeroSectionPreviewImageSrc(data)}
        description="Strona internetowa Miejskiego Schroniska dla Zwierząt w Tychach. Tutaj znajdziesz informacje na temat Schroniska, sprawdzisz aktualności, a także dowiesz się na temat kotów oraz psów do adopcji."
      />
      {/*Hero*/}
      <div className="mb-10 lg:mb-20">
        <SectionContainer className={clsx("mx-auto px-3", theme.mainText)}>
          <div
            className={clsx(
              "mx-auto flex aspect-[16/10] max-h-70-screen w-full flex-col items-end justify-end lg:relative"
            )}
          >
            <GatsbyImage
              className="h-full w-full rounded-md"
              image={heroPhoto}
              alt={heroSection?.description}
              imgStyle={{ width: "full", margin: "0 auto" }}
            />
            <div
              className={clsx(
                "my-4 flex w-full flex-col items-center justify-end rounded-b-md p-4 text-center lg:absolute lg:my-0 lg:bg-gray-800/40",
                isHeightContrastEnable && "text-white",
                !isHeightContrastEnable && "lg:text-white"
              )}
            >
              <h1 className="py-2 text-3xl font-bold lg:text-5xl">
                {heroSection?.title}
              </h1>
              {heroSection?.subtitle && (
                <p className="py-2 text-xl lg:text-2xl">
                  {heroSection?.subtitle}
                </p>
              )}
              <div className="my-4 flex flex-col lg:grid lg:grid-cols-2">
                <CTALink
                  className="mx-auto mb-4 lg:mx-2"
                  to="/galeria/"
                  title="Odwiedź naszą galerię"
                  theme={theme}
                />
                <CTALink
                  className="mx-auto mb-4 lg:mx-2"
                  to="#jak-pomoc"
                  title="Jak pomóc?"
                  theme={theme}
                />
              </div>
            </div>
          </div>
        </SectionContainer>
      </div>
      <div className="relative -mt-12 lg:-mt-24">
        <Waves />
      </div>
      {lostAndFound?.length > 0 && (
        <section className="bg-white px-2 pt-8 md:px-8 xl:px-16">
          <SectionContainer className="mx-auto items-center justify-center px-3 md:flex-row">
            <h2 className="my-10 w-full text-center text-5xl font-bold leading-tight">
              Ostatnie zgłoszenia
            </h2>
            <div
              className={clsx(
                LostAndFound.length === 3 && "xl:grid xl:grid-cols-3",
                LostAndFound.length === 2 && "xl:grid xl:grid-cols-2",
                LostAndFound.length === 1 && "flex",
                "flex flex-wrap justify-center gap-8"
              )}
            >
              {lostAndFound.map((announcement) => (
                <LostAndFoundCard
                  key={announcement.id}
                  id={announcement.id}
                  theme={theme}
                  className={clsx(
                    "w-11/12 sm:w-3/5 lg:w-5/12",
                    LostAndFound.length === 3 && " xl:w-full",
                    LostAndFound.length !== 3 && "xl:w-3/12"
                  )}
                  name={announcement.name}
                  kind={announcement.kind}
                  sex={announcement.sex}
                  shelterId={announcement.shelter_id}
                  date={dateDisplay(announcement.event_date)}
                  location={formatLocation(
                    announcement.location_primary,
                    announcement.location_secondary
                  )}
                  description={announcement.description ?? ""}
                  status={announcement.type}
                  gatsbyImage={getImage(announcement.photo?.imageFile)}
                  gatsbyImageAlt={announcement.photo?.description ?? ""}
                />
              ))}
            </div>
            <CTALink
              to="/zagubione-znalezione/"
              title="Sprawdź wszystkie zgłoszenia"
              className="mx-auto mb-6 mt-12 lg:mx-0"
              colorClassNames={clsx(theme.bgStyle, "text-white")}
              theme={theme}
            />
          </SectionContainer>
        </section>
      )}
      <section
        id="jak-pomoc"
        className="min-h-70-screen bg-gradient-to-b from-white to-gray-100 px-2 pt-8 md:px-8 xl:px-16"
      >
        <div className="container m-auto">
          <h2
            id="ScrollPoint"
            className=" my-10 w-full text-center text-5xl font-bold leading-tight"
          >
            {mainSection.title}
          </h2>

          <div className="flex justify-center ">
            <div
              id={"mainSectionText"}
              className="m-auto my-10 p-4 text-lg leading-relaxed lg:w-3/4 xl:w-1/2"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `${mainSection.first_section}`,
                }}
              ></div>
              {mainSection.account_visibility ? (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${mainSection.account_section}`,
                    }}
                  />
                  <button
                    className={clsx(
                      "my-10 w-full text-center text-2xl font-bold leading-tight",
                      theme.textAccent
                    )}
                    onClick={() => {
                      navigator.clipboard.writeText(mainSection.account_number);
                      setIsAccountNumberCopied(true);
                      setTimeout(() => {
                        setIsAccountNumberCopied(false);
                      }, 3000);
                    }}
                  >
                    {accountNumberSlice(mainSection.account_number)}
                  </button>
                  <span
                    className={`-mt-10 mb-10 block text-center text-sm ${
                      isAccountNumberCopied ? theme.textAccent : null
                    }`}
                  >
                    {isAccountNumberCopied
                      ? `Numer został skopiowany`
                      : `(Kliknij numer konta bankowego żeby skopiować)`}
                  </span>
                </>
              ) : null}
              <div
                dangerouslySetInnerHTML={{
                  __html: `${mainSection.secound_section}`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <svg
        viewBox="0 0 1439 147"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <g transform="translate(-1.000000, -14.000000)" fillRule="nonzero">
            <g fill="#F3F4F6">
              <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z" />
            </g>
            <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
              <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000)">
                <path
                  d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                  opacity="0.100000001"
                />
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                />
                <path
                  d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                  opacity="0.200000003"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      <section className="px-2 pt-8 md:px-8 xl:px-16">
        <h3
          className={clsx(
            "mb-20 mt-10 w-full text-center text-5xl font-bold leading-tight",
            isHeightContrastEnable && "text-white"
          )}
        >
          Informacje kontaktowe
        </h3>
        <SectionContainer className="mx-auto items-center justify-around px-3 md:flex-row">
          {/*Map*/}
          <div className="flex w-full flex-col md:px-10 md:text-left lg:w-3/5 lg:flex-col lg:items-start lg:justify-center">
            <div className="flex w-full flex-col items-center">
              <a
                target="blank"
                href="https://goo.gl/maps/nvJ6aoYvGFvuz8JS6"
                title="Lokalizacja Schroniska w Google Maps"
              >
                <StaticImage
                  alt="Przedstawienie lokalizacji Schroniska w Google Maps"
                  src="../images/Assets/GOOGLE_MAPS.png"
                  className="rounded-sm"
                />
              </a>
              <CTALink
                className="mb-4 mt-8"
                to="/kontakt"
                title="Więcej informacji"
                theme={theme}
              />
            </div>
          </div>
          {/*Desktop img */}
          <div className="relative mt-auto w-80">
            <StaticImage
              className="relative bottom-0 z-20 w-full"
              src="../images/dogo.png"
              alt=""
            />
            <div
              style={{
                background:
                  "linear-gradient(25deg, rgba(255,255,255,1) 0%, rgba(90,227,255,1) 100%)",
              }}
              className="absolute bottom-0 left-0 h-4/5 w-full rounded-full"
            />
          </div>
        </SectionContainer>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage {
    directus {
      lost_and_found(filter: { status: { _eq: "published" } }, limit: 3) {
        id
        status
        user_created {
          first_name
          last_name
        }
        type
        kind
        sex
        shelter_id
        event_date
        location_primary
        location_secondary
        name
        photo {
          id
          description
          imageFile {
            id
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }
        }
      }
      hero_section {
        id
        hero_image {
          id
          imageFile {
            childImageSharp {
              id
              gatsbyImageData(quality: 90, width: 1520)
            }
            previewImage: childImageSharp {
              resize(
                width: 1200
                height: 630
                quality: 85
                cropFocus: ATTENTION
                fit: COVER
              ) {
                src
              }
            }
          }
        }
        description
        title
        subtitle
      }
      main_section {
        id
        title
        first_section
        account_visibility
        account_section
        account_number
        secound_section
      }
    }
  }
`;
